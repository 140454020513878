@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-indigo-950;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  img {
    object-fit: cover;
  }
}

@layer components {
  .logo {
    @apply font-display text-3xl tracking-wide text-indigo-600;
  }
  .pageTitle {
    @apply font-dec text-2xl font-semibold xs:text-xl;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.fc-authkit-signin-button button {
  background-color: #7c65c1;
}
